#typing-animation-text {
  height: 100vh;
  width: 100vw;

  display: grid;
  place-items: center;
  text-align: center;
  font-size: 5em;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  font-family: Verdana, "Helvetica Neue", sans-serif;
  pointer-events: none;
}

.typing-animation-cursor {
  color: aquamarine !important;
}

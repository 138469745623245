#contact-page {
  height: 100vh;
  width: 100vw;
  padding-bottom: 5vh;

  display: flex;
  flex-wrap: wrap;
}

#contact-items-container {
  margin: auto auto 0 auto;
  height: 70vh;
  width: 100vw;
  align-items: center;
  display: flex;
}

#embedmap-display {
  margin: auto;
  height: 70vh;
  width: 70vw;
  position: relative;
  border-radius: 50px 50px 50px 50px;
}

iframe {
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: 10px 10px 10px 10px;
}

@media screen and (min-width: 1025px) {
  form {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 20vw;

    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1025px) {
  #contact-items-container {
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 5vh;
  }
  #embedmap-display {
    margin: auto;

    position: relative;
    border-radius: 50px 50px 50px 50px;
  }
}

h2 {
  color: white;
  font-size: 2rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  font-weight: 500;
}

p {
  color: gray;
  font-size: 0.875rem; /* 30px */
  line-height: 1.625;
  font-weight: 500;
}

form div {
  margin-top: 2vh;
}
label {
  line-height: 1.75rem; /* 28px */
  font-size: 1.25rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  color: rgb(156 163 175);
}

input {
  margin-top: 0.5vh;
  width: 100%;
  height: 5vh;
  background-color: rgb(31 41 55);
  border: 1px solid #caaaf3;
  border-radius: 10px 10px 10px 10px;
  color: white;
  padding-left: 1rem;
}

textarea {
  margin-top: 0.5vh;
  width: 100%;
  height: 13vh;
  background-color: rgb(31 41 55);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #caaaf3;
  padding-left: 1rem;
  padding-top: 0.75rem;
  color: white;
}

button {
  margin-top: 2vh;
  width: 100%;
  height: 5vh;
  color: white;
  background: #5046e5;
  border: 1px solid gray;
  border-radius: 3px 3px 3px 3px;
  font-size: 1.25rem;
  cursor: pointer;
}

button:hover {
  opacity: 0.9;
}

.form-submitted-alert-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  height: 4vh;
  color: black;
  font-size: 14px;
  font-variant: tabular-nums;
  display: flex;
  align-items: center;
  justify-content: center;
}

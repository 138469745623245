#technologies-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
}

.technologies-container {
  margin-top: 0;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 25vw);
  gap: 10px;
}

.technologies-info-container {
  margin: 20px auto auto auto;
  display: flex;
  flex-direction: column;
}

.technology {
  width: 25vw;
  height: 7vh;
  background: #1f2937;
  border-radius: 5px;
  border: 1px solid #caaaf3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2em;
}

.technologies-icon {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4em;
  color: white;
}

.technology-text {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: 10px;
  font-size: 0.5em;
  color: white;
}

.technology-icon {
  font-size: x-large;
  margin-left: 10px;
  color: mediumaquamarine;
}

@media screen and (max-width: 700px) {
  .technologies-container {
    margin-top: 0;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(2, 30vw);
    gap: 10px;
  }
  .technology {
    width: 30vw;
    height: 7vh;
    background: #1f2937;
    border-radius: 5px;
    border: 1px solid #caaaf3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2em;
  }
}

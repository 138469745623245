#projects-page {
  height: 100vh;
  width: 100vw;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 700px) {
  #projects-container {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 30vw);
    gap: 2.5vw;
  }

  .img-container {
    position: relative;
    margin: auto;
    width: 30vw;
    height: 30vh;
  }

  img {
    position: absolute;
    width: 30vw;
    height: 30vh;
  }

  .hover-data {
    position: absolute;
    width: 30vw;
    height: 30vh;
    background-color: rgb(17 24 39);
    opacity: 0;
    display: grid;
    place-items: center;
    margin: auto;
  }
  .hover-data:hover {
    opacity: 0.9;
    z-index: 10;
  }
}

@media screen and (max-width: 700px) {
  #projects-page {
    height: fit-content;
  }
  #projects-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.5vh;
    margin: auto;
  }

  .img-container {
    position: relative;
    margin: auto;
    width: 75vw;
    height: 30vh;
  }

  img {
    position: absolute;
    width: 75vw;
    height: 30vh;
  }

  .hover-data {
    position: absolute;
    width: 75vw;
    height: 30vh;
    background-color: rgb(17 24 39);
    opacity: 0;
    display: grid;
    place-items: center;
    margin: auto;
  }
  .hover-data:hover {
    opacity: 0.9;
    z-index: 10;
  }
}

.title {
  line-height: 1.625;
  font-size: 1.5rem; /* 14px */
  color: rgb(74 222 128);
}

.description {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
}

h1 {
  margin: 0 auto;
  font-size: 4em;
  color: white;
}

.projects-icon {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4em;
  color: white;
}

@import "~antd/dist/antd.css";

.ant-menu {
  position: fixed;
  width: 100%;
  background: rgb(17 24 39) !important;
  border: 0;
  padding-bottom: 0;
  z-index: 200;
}

.name-logo {
  font-size: 18px;
  color: #caaaf3 !important;
}
.name-logo:hover {
  background: transparent !important;
}
